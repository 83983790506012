import React, { useState, useEffect } from 'react';
import loadable from '@loadable/component';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import FallbackPage from './FallbackPage';
import { ASSET_NAME } from './LandingPage.duck';

import appSettings from '../../config/settings';

import css from "./landingpage.module.css"

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

export const LandingPageComponent = props => {
  const { pageAssetsData, inProgress, error } = props;
  const [gmConfirmationShouldRender, setGmConfirmationShouldRender] = useState(false);

  useEffect(() => {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      const value = localStorage.getItem(key);
      console.log(`${key}: ${value}`);
    }

    return;
  }, []);

  const sharetribeSdk = require('sharetribe-flex-sdk');
  const sdk = sharetribeSdk.createInstance({
    clientId: appSettings.sdk.clientId
  });

  useEffect(() => {
    if (localStorage.getItem("isGM") === "true") {
      console.log("HERE is gm is true")
      sdk.currentUser.updateProfile({
        publicData: { isGM: true }
      }, { expand: true, include: ["profileImage"] })
      .then(res => {
        console.log("Updated user GM status!")
        localStorage.removeItem("isGM");
        window.location.reload();
      })
      .catch(error => {
        setGmConfirmationShouldRender(false);
        console.log(gmConfirmationShouldRender);
        localStorage.removeItem("isGM");
        console.warn("User is not logged in yet can't update profile!");
      });
    }
  }, []);



  sdk.currentUser.show()
  .then(res => {
    const is_GM = res.data.data.attributes.profile.publicData.isGM;
    const is_ConfirmedGM = res.data.data.attributes.profile.protectedData.isConfirmedGM;
    const shouldRender = is_GM && !is_ConfirmedGM;
    setGmConfirmationShouldRender(shouldRender);
  })
  .catch(error => {
    setGmConfirmationShouldRender(false);
    console.warn("User is not logged in yet can't show profile!");
  });

  const onClickConfirmGMStatus = (sdk) => {
    sdk.currentUser.updateProfile({
      protectedData: { isConfirmedGM: true }
    }, { expand: true, include: ["profileImage"] })
    .then(res => {
      console.log("Updated user GM confirmation status!")
      const is_GM = res.data.data.attributes.profile.publicData.isGM;
      const is_ConfirmedGM = res.data.data.attributes.profile.protectedData.isConfirmedGM;
      const shouldRender = is_GM && !is_ConfirmedGM;
      console.log({ is_GM, is_ConfirmedGM, shouldRender })
      setGmConfirmationShouldRender(shouldRender);

      location.href = '/profile-settings';

      return;
    })
    .catch(error => {
      setGmConfirmationShouldRender(false);
      console.warn("User is not logged in yet can't update profile!");
      location.reload();

      return;
    });
  }

  return (
    <>
      {
        gmConfirmationShouldRender ?
          <div className={css.gmConfirmation}>
            <h4>
              Please finish your GM profile!
            </h4>
            <button onClick={() => onClickConfirmGMStatus(sdk)}>Confirm GM status</button>
          </div> : null
      }
      <PageBuilder
        pageAssetsData={pageAssetsData?.[camelize(ASSET_NAME)]?.data}
        inProgress={inProgress}
        error={error}
        isLandingPage={true}
        fallbackPage={<FallbackPage error={error} />}
      />
    </>
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  return { pageAssetsData, inProgress, error };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(connect(mapStateToProps))(LandingPageComponent);

export default LandingPage;
